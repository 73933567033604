import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdCards } from '@/components/AdCards/AdCards';
import * as S from '@/components/AdsSection/AdsSection.styles';
import { AdsSectionProps } from '@/components/AdsSection/AdsSection.types';
import { Button } from '@/components/UI/Button/Button';
import { InfiniteLoader } from '@/components/InfiniteLoader/InfiniteLoader';
import { getDataStatAttr } from '@/utils/helpers';
import { Container } from '@/components/Container/Container.styles';

export const AdsSection: React.FC<AdsSectionProps> = ({
  title,
  hasTopMargin,
  banner,
  bannerInList,
  appearance = 'titleWithBtn',
  onShowMoreClick,
  count,
  hasBottomButton = true,
  onLoadMore,
  ads,
  adsLoading,
  showMoreText,
  showMoreDataStat,
  listDataStat,
  hasAdManagerCard,
  className,
  onLatestAdsClick,
}) => {
  const { t } = useTranslation();
  const isOnlyTitle = appearance === 'onlyTitle';

  return (
    <>
      <S.Box
        $hasTopMargin={hasTopMargin}
        $appearance={appearance}
        className={className}
      >
        <S.TitleWrapper $appearance={appearance}>
          {title ? (
            isOnlyTitle ? (
              <S.TitleV2 $variant="3" $appearance={appearance}>
                {title}
                <S.Count $variant="1" as="span">
                  {count}
                </S.Count>
              </S.TitleV2>
            ) : (
              <S.TitleBox $appearance={appearance}>
                <S.Title $variant="2" $appearance={appearance}>
                  {title}
                </S.Title>
                {onShowMoreClick && (
                  <S.More $variant="1" onClick={onShowMoreClick}>
                    {t('common.show_all')}
                  </S.More>
                )}
                {onLatestAdsClick && (
                  <S.Latest $isUnderlined onClick={onLatestAdsClick}>
                    {t('pages.ads.latest_ads')}
                  </S.Latest>
                )}
              </S.TitleBox>
            )
          ) : null}
        </S.TitleWrapper>

        <Container>
          <AdCards
            ads={ads}
            isInitialLoading={adsLoading}
            listDataStat={listDataStat}
            hasAdManagerCard={hasAdManagerCard}
            bannerInList={bannerInList}
          />

          {isOnlyTitle && hasBottomButton && onShowMoreClick && (
            <S.MoreBottomBox>
              <Button
                appearance="secondaryWhite"
                onClick={onShowMoreClick}
                {...getDataStatAttr({ dataStat: showMoreDataStat })}
              >
                {showMoreText ?? t('common.show_all')}
              </Button>
            </S.MoreBottomBox>
          )}
          {banner}
        </Container>
      </S.Box>

      {onLoadMore && <InfiniteLoader onVisit={onLoadMore} />}
    </>
  );
};
